import { capitalize } from "@mui/material";
import {
  BubbleText,
  Search,
  Subtract,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  StyledCurrentCountOnTotalContainer,
  StyledCurrentCountOnTotalText,
} from "@/components/Agents/List";
import { EmptyDataInDB } from "@/components/shared/EmptyComponent";
import { Loading } from "@/components/shared/Loading";
import { RolesProfileIcon } from "@/components/shared/RolesProfileIcon";
import { isRolesProfileCode } from "@/components/shared/RolesProfileIcon/types";
import { useFormatDate } from "@/hooks/i18n/useFormatDate";
import { useUserCompanies } from "@/hooks/user/getUserCompanies/hooks.tsx";

import { getUserRoleDisplayedName } from "./helper";
import {
  StyledButton,
  StyledButtonContainer,
  StyledCell,
  StyledCellHeaderText,
  StyledCellText,
  StyledCellWithEndArrow,
  StyledContainer,
  StyledCustomTableContainer,
  StyledDivider,
  StyledRightIcon,
  StyledRoundedLeftContainer,
  StyledRowContainerLink,
  StyledSearchField,
  StyledSearchFieldContainer,
  StyledTable,
  StyledTableHeader,
  StyledTitle,
} from "./styles";

const pageSize = 20;

export const AccountsList: React.FC<{ userId: string }> = ({ userId }) => {
  const { t: tUsers } = useTranslation("users");
  const { t: tProfiles } = useTranslation("profiles");
  const { t: tCompanies } = useTranslation("companies");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");
  const formatDate = useFormatDate();
  const formattedDate = (date: any) => {
    const millisecondsToDateObject = new Date(Number(date));

    return formatDate(millisecondsToDateObject, "short");
  };
  const { userCompanies: listOfCompanies, isLoading } = useUserCompanies(
    userId,
    searchTerm,
  );
  const totalNumberOfCompanies = listOfCompanies?.length || 0;
  const totalNumberOfPages = Math.ceil(totalNumberOfCompanies / pageSize);
  const iconProps = {
    width: "20px",
    height: "20px",
  };

  return (
    <StyledContainer>
      <StyledRoundedLeftContainer>
        <StyledTitle variant='title2'>
          {tUsers("users.list.subtitle.list")}
        </StyledTitle>
        <StyledDivider />
        <StyledSearchFieldContainer>
          <StyledSearchField
            dataTestId='text-field'
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            placeholder={tUsers("users.list.searchPlaceholder")}
            startIcon={<Search width={18} height={18} />}
          />
        </StyledSearchFieldContainer>

        <StyledCustomTableContainer>
          <StyledTableHeader>
            <StyledCell>
              <StyledCellHeaderText variant='text2'>
                {tUsers("users.list.data.accountNumber")}
              </StyledCellHeaderText>
            </StyledCell>
            <StyledCell>
              <StyledCellHeaderText variant='text2'>
                {tUsers("users.list.data.companyName")}
              </StyledCellHeaderText>
            </StyledCell>
            <StyledCell>
              <StyledCellHeaderText variant='text2'>
                {tUsers("users.list.data.accountProfile")}
              </StyledCellHeaderText>
            </StyledCell>
            <StyledCell>
              <StyledCellHeaderText variant='text2'>
                {tUsers("users.list.data.status")}
              </StyledCellHeaderText>
            </StyledCell>
            <StyledCell>
              <StyledCellHeaderText variant='text2'>
                {tUsers("users.list.data.createdTs")}
              </StyledCellHeaderText>
            </StyledCell>
            <StyledCell>
              <StyledCellHeaderText variant='text2'>
                {tUsers("users.list.data.lastConnection")}
              </StyledCellHeaderText>
            </StyledCell>
            <StyledCell>
              <StyledCellHeaderText variant='text2'>{""}</StyledCellHeaderText>
            </StyledCell>
          </StyledTableHeader>
          <Loading isLoading={isLoading}>
            <StyledTable>
              {listOfCompanies ? (
                listOfCompanies.map((company) => (
                  <StyledRowContainerLink
                    to={`/accounts/${company.accountId}`}
                    key={company.accountId}
                  >
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {company.AccountIdData?.number}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {company.AccountIdData?.name}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      {isRolesProfileCode(
                        company?.Subscriptions?.[0]?.RolesProfileIdData?.code ??
                          "",
                      ) ? (
                        <RolesProfileIcon
                          code={
                            (company?.Subscriptions?.[0]?.RolesProfileIdData
                              ?.code as
                              | "ADMIN"
                              | "MANAGER"
                              | "ACCOUNTANT"
                              | "VIEWER") ?? ""
                          }
                          size={28}
                        />
                      ) : null}
                      <StyledCellText variant='text2'>
                        {getUserRoleDisplayedName({
                          code: company?.Subscriptions?.[0]?.RolesProfileIdData
                            ?.code,
                          name: company?.Subscriptions?.[0]?.RolesProfileIdData
                            ?.name,
                          t: tProfiles,
                        })}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        <BubbleText
                          text={capitalize(
                            tCompanies(
                              `companies.list.status.${company.status}.label`.toLowerCase(),
                            ),
                          )}
                          variant={
                            company.status.toLowerCase() === "active"
                              ? "green"
                              : "grey"
                          }
                        />
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {formattedDate(company.invitedTs)}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCell>
                      <StyledCellText variant='text2'>
                        {formattedDate(company?.lastUsedTs)}
                      </StyledCellText>
                    </StyledCell>
                    <StyledCellWithEndArrow>
                      <Text variant='text2'>
                        <StyledRightIcon />
                      </Text>
                    </StyledCellWithEndArrow>
                  </StyledRowContainerLink>
                ))
              ) : (
                <EmptyDataInDB principalText={tUsers("common.noData")} />
              )}
            </StyledTable>
          </Loading>
        </StyledCustomTableContainer>
        {totalNumberOfPages > 1 ? (
          <StyledButtonContainer>
            <StyledButton
              leftIcon={<Subtract {...iconProps} />}
              variant='secondary'
              buttonText={tUsers("common.pagination.next_page", { pageSize })}
              isLowercase={true}
              onClick={() => setCurrentPage((prev) => prev + 1)}
            ></StyledButton>
          </StyledButtonContainer>
        ) : null}
        <StyledCurrentCountOnTotalContainer>
          <StyledCurrentCountOnTotalText variant='sub3'>
            {tUsers("users.list.namesWithCurrentCountOnTotal.name", {
              count: totalNumberOfCompanies * currentPage,
              total: totalNumberOfCompanies,
            })}
          </StyledCurrentCountOnTotalText>
        </StyledCurrentCountOnTotalContainer>
      </StyledRoundedLeftContainer>
    </StyledContainer>
  );
};
