import { capitalize } from "@mui/material";
import {
  BubbleText,
  ContextualMenu,
  Subtract,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { usePaginatedGetSearchAgents } from "@/api/graphql/agents/search";
import EditAgent from "@/components/Agents/Edit/component.tsx";
import {
  StyledButton,
  StyledButtonContainer,
  StyledCell,
  StyledCellContextualMenu,
  StyledCellHeaderText,
  StyledCellText,
  StyledCurrentCountOnTotalContainer,
  StyledCurrentCountOnTotalText,
  StyledCustomTableContainer,
  StyledRowContainerLink,
  StyledTable,
  StyledTableHeader,
} from "@/components/Agents/List";
import { getFilters } from "@/components/Agents/List/mappers.ts";
import { AgentsListProps } from "@/components/Agents/List/types.ts";
import { useFormattedDate } from "@/components/Agents/List/useFormattedDate.ts";
import { getStatus, getStatusLabel } from "@/components/BackOffice/helper.ts";
import { Loading } from "@/components/shared/Loading";
import { useAddSnackbar } from "@/contexts/snackbar";
import { useDisableSupportUser } from "@/hooks/agent/disable";
import { useEnableSupportUser } from "@/hooks/agent/enable";
import { FeSupportUser } from "@/types/agents.ts";

import { AGENT_PROFILE_ICON } from "./constants";

export const AgentsListComponent = (props: AgentsListProps) => {
  const {
    data: listOfAgents,
    isLoading,
    totalOfDisplayableSupportUsersWithCurrentParameters,
    fetchNextPage,
    refetch,
  } = usePaginatedGetSearchAgents({
    filters: getFilters(props),
  });
  //TODO: change useTranslation to specify a namespace
  const { t: tUsers } = useTranslation("users");
  const { t: tCompanies } = useTranslation("companies");
  const { t: tAgents } = useTranslation("agents");
  const [menuOpen, setMenuOpen] = useState(-1);
  const addSnackbar = useAddSnackbar();
  const [selectedAgent, setSelectedAgent] = useState<FeSupportUser | null>(
    null,
  );
  const { disableSupportUser } = useDisableSupportUser();
  const { enableSupportUser } = useEnableSupportUser();
  const formattedDate = useFormattedDate();
  //TODO: remove this function from the component
  const getLinks = (agent: FeSupportUser) => {
    switch (agent.status) {
      case "SUPPORT_USER_ENABLED":
        return [
          {
            text: tUsers("users.actions.edit"),
            size: "s",
            textAlign: "left",
            className: "customMenuItem",
            onClick: () => {
              setSelectedAgent(() => agent);
            },
          },
          {
            text: tUsers("users.actions.deactivate"),
            size: "s",
            color: "red",
            textAlign: "left",
            onClick: () => {
              disableSupportUser(agent.id)
                .then(() => {
                  addSnackbar({
                    type: "success",
                    title: tUsers("users.actions.deactivated"),
                    message: "",
                    isAutoClose: true,
                  });
                })
                .catch(() => {
                  addSnackbar({
                    type: "error",
                    title: tUsers("users.actions.deactivated"),
                    message: ``,
                    isAutoClose: true,
                  });
                })
                .finally(() => {
                  refetch();
                  props.refetchCountStatus();
                });
            },
          },
        ];
      case "SUPPORT_USER_DISABLED":
        return [
          {
            text: tUsers("users.actions.reactivate"),
            size: "s",
            textAlign: "left",
            onClick: () => {
              enableSupportUser(agent.id)
                .then(() => {
                  addSnackbar({
                    type: "success",
                    title: tUsers("users.actions.reactivated"),
                    message: "",
                    isAutoClose: true,
                  });
                })
                .catch(() => {
                  addSnackbar({
                    type: "error",
                    title: tUsers("users.actions.reactivated"),
                    message: ``,
                    isAutoClose: true,
                  });
                })
                .finally(() => {
                  refetch();
                  props.refetchCountStatus();
                });
            },
          },
        ];
      case "SUPPORT_USER_INVITING":
        return [
          {
            text: tUsers("users.actions.reinvite"),
            size: "s",
            textAlign: "left",
            className: "customMenuItem",
            onClick: () => {
              addSnackbar({
                type: "success",
                title: tUsers("users.actions.reinvited"),
                message: tUsers("users.actions.reinvitedDescription", {
                  email: agent.email,
                }),
                isAutoClose: true,
              });
            },
          },
          {
            text: tUsers("users.actions.deactivate"),
            size: "s",
            textAlign: "left",
            color: "red",
            onClick: () => {
              addSnackbar({
                type: "success",
                title: tUsers("users.actions.cancelInvitation"),
                message: tUsers("users.actions.cancelInvitationDescription", {
                  email: agent.email,
                }),
                isAutoClose: true,
              });
            },
          },
        ];
      default:
        return [];
    }
  };

  //TODO: Using the DS CustomTable
  return (
    <>
      {selectedAgent && (
        <EditAgent
          isOpen={Boolean(selectedAgent)}
          onClose={() => setSelectedAgent(() => null)}
          agent={{
            id: selectedAgent!.id,
            firstName: selectedAgent?.firstName || "",
            lastName: selectedAgent?.lastName || "",

            email: selectedAgent?.email || "",
            iGG: selectedAgent?.igg || "",
            jobTitle: selectedAgent?.jobTitle || "",
            profile:
              selectedAgent?.accountProfile === "SUPER_AGENT"
                ? "SUPER_AGENT"
                : "AGENT",
          }}
        />
      )}
      <Loading isLoading={isLoading || false}>
        <>
          <StyledCustomTableContainer>
            <StyledTableHeader>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.email")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.name")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>IGG</StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.accountProfile")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.status")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.createdTs")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {tCompanies("companies.list.data.lastConnection")}
                </StyledCellHeaderText>
              </StyledCell>
              <StyledCell>
                <StyledCellHeaderText variant='text2'>
                  {""}
                </StyledCellHeaderText>
              </StyledCell>
            </StyledTableHeader>

            <StyledTable>
              {(listOfAgents || []).map((profile, index) => (
                <StyledRowContainerLink key={profile.id}>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {profile.email}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {profile.firstName} {profile.lastName}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {profile.igg}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    {AGENT_PROFILE_ICON[profile.accountProfile]}
                    <StyledCellText variant='text2'>
                      {/* TODO: use a mapper to have a formatted profile*/}
                      {tCompanies(
                        `companies.list.profile.${profile.accountProfile}`,
                      )}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      <BubbleText
                        text={capitalize(
                          tUsers(
                            `users.list.status.${getStatusLabel(profile.status)}.label`.toLowerCase(),
                          ),
                        )}
                        variant={getStatus(profile.status)}
                      />
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {formattedDate(profile.createdTs)}
                    </StyledCellText>
                  </StyledCell>
                  <StyledCell>
                    <StyledCellText variant='text2'>
                      {formattedDate(profile.updatedAt)}
                    </StyledCellText>
                  </StyledCell>

                  <StyledCellContextualMenu>
                    <Text variant='text2'>
                      <ContextualMenu
                        dataTestId='contextual-menu'
                        open={index === menuOpen}
                        handleClose={() => {
                          setMenuOpen(-1);
                        }}
                        handleOpen={() => {
                          setMenuOpen(index);
                        }}
                        links={getLinks(profile)}
                      />
                    </Text>
                  </StyledCellContextualMenu>
                </StyledRowContainerLink>
              ))}
            </StyledTable>
          </StyledCustomTableContainer>
          {totalOfDisplayableSupportUsersWithCurrentParameters >
          (listOfAgents?.length || 0) ? (
            <StyledButtonContainer>
              <StyledButton
                leftIcon={<Subtract />}
                variant='secondary'
                buttonText={"Load more"}
                isLowercase={true}
                onClick={() =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  fetchNextPage()
                }
              ></StyledButton>
            </StyledButtonContainer>
          ) : null}
        </>
      </Loading>
      <StyledCurrentCountOnTotalContainer>
        <StyledCurrentCountOnTotalText variant='sub3'>
          {tAgents("agents.list.namesWithCurrentCountOnTotal.name", {
            count: listOfAgents?.length || 0,
            total: totalOfDisplayableSupportUsersWithCurrentParameters,
          })}
        </StyledCurrentCountOnTotalText>
      </StyledCurrentCountOnTotalContainer>
    </>
  );
};
