import { createFileRoute } from "@tanstack/react-router";

import { UserDetail } from "@/components/Users/details";

const UserProfileView = () => {
  // eslint-disable-next-line no-use-before-define
  const { id } = Route.useParams();

  return <UserDetail id={id} />;
};

export const Route = createFileRoute("/users/$id")({
  component: UserProfileView,
});
